import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';
import { PrimaryBtn } from '../Buttons/Primary';
import { SecondaryBtn } from '../Buttons/Secondary';
import { h1, h2 } from '../Headings';

const Article = styled.article`
    max-width: 1270px;
    margin: 0 auto;
    padding: 32px 16px;

    > h2 {
        margin: 0 0 32px;
        ${h1};
    }

    > p {
        color: ${({ theme }) => theme.darkTeal};
        margin: 0;
    }

    @media (min-width: 768px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    @media (min-width: 1280px) {
        padding-top: 128px;
        padding-bottom: 128px;
    }
`;

const Box = styled.div`
    padding: 32px 64px;
    background: ${({ theme }) => theme.white};
    border-radius: 32px;
    max-width: 881px;
    margin: 128px auto 0;
    text-align: center;

    > h3 {
        ${h2};
        margin: 0 0 32px;
    }

    > p {
        margin: 0 0 32px;
        color: ${({ theme }) => theme.darkTeal};
    }

    a:first-of-type {
        margin-right: 32px;
    }
`;

export default function NewPatientsSection() {
    return (
        <Article>
            <h2>New Patients</h2>
            <p>
                We love meeting new Patients! We live in a warm and friendly community and our best
                Referrals are from other Patients and Dentists. We know how scary visiting the
                orthodontist can be, especially the first time. Not only do you have paperwork to
                fill out and insurance to sort out, but also many people have an overwhelming fear
                of the dentist and orthodontist.
                <br /> <br />
                Because of this, we work hard to offer our patients a welcoming and comfortable
                office. We also have a wonderful staff dedicated to helping you and giving you a
                good visit every time that you step through our doors.
                <br /> <br />
                Before you come in, don’t hesitate to ask any of our staff members any questions or
                voice your concerns. We want to make sure that you are comfortable before you even
                come into our office.
                <br /> <br />
                Please complete your New Patient Information, Health History, and HIPPA information
                before your first visit.
            </p>

            <PrimaryBtn
                as="a"
                href="https://patientforms.csdental.com/pub/b624f8a1-0b8c-eb11-85aa-000d3a12cfbd/forms/"
                target="_blank"
                css={css`
                    margin: 32px 0;
                `}
            >
                FILL THIS FORM BEFORE YOUR FIRST VISIT
            </PrimaryBtn>
            <p>
                {' '}
                If you are worried about the paperwork, we can mail you the forms so that you can
                fill them out before your appointment. Our entire staff is also here for you during
                your appointment. We will do everything we can to ensure you have a good visit. We
                are here to answer any questions or alleviate any concerns before you even see the
                dentist!
            </p>

            <Box>
                <h3>Got questions?</h3>
                <p>
                    If you have any questions or would like to set up an appointment, feel free to
                    contact us today
                </p>
                <PrimaryBtn as={Link} to="/contact-us/">
                    contact us
                </PrimaryBtn>
                <SecondaryBtn as="a" href="tel:+13406437673">
                    call us
                </SecondaryBtn>
            </Box>
        </Article>
    );
}
