import React from 'react';
import CommonHero from '../components/layout/CommonHero';
import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';
import NewPatientsSection from '../components/Sections/NewPatients';

export default function NewPatients() {
    return (
        <Layout>
            <Seo
                title="patient services"
                description="We live in a warm and friendly community and our best Referrals are from other Patients and Dentists"
            />
            <CommonHero title="patient services" />
            <NewPatientsSection />
        </Layout>
    );
}
